<template>
  <div class="tabs-style">
    <account-role-title role="Admin" />
    <!-- <breadcrumbs /> -->
    <span class="title">{{ $t("Refunds") }}</span>
    <v-tabs v-model="tab" show-arrows class="deals-tabs">
      <v-tab
          v-for="(tab, index) in tabs"
          :key="index"
          @click="$router.push({name: getRouteName, params:{tab:tab.route, store_id: $route.params.store_id, user_id: $route.params.user_id}, query:{t:new Date().getTime()}})"
      >
        <span>{{ $t(tab.title) }}</span>
      </v-tab>
      <!-- tabs item -->
      <v-tabs-items v-model="tab">
        <v-tab-item v-for="(tab, index) in tabs" :key="index">
          <div class="d-flex justify-end">
            <orders-search url="admin/refunds" :userId="userId" :storeId="storeId"/>
          </div>
          <!-- tab component -->
          <orders-table v-if="tab.route == $route.params.tab" url="admin/refunds" order-link="admin-order" :userId="userId" :storeId="storeId"></orders-table>
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
  </div>
</template>

<script>
import ordersSearch from "@/views/cabinet/order/order-list/OrdersSearch";
import ordersTable from "@/views/cabinet/order/order-list/OrdersTable";
import {useRouter} from "@core/utils";
import {ref, watch, computed} from "@vue/composition-api/dist/vue-composition-api";
export default {
  name: "AdminRefundsList",
  components: {
    ordersTable,
    ordersSearch
  },
  setup() {
    const { route } = useRouter()
    const tabs = [
      { title: 'All', route: 'all' },
      { title: 'Refund Requests', route: 'refund-requests' },
      { title: 'Escalated', route: 'escalated' },
      { title: 'Refunded', route: 'refunded' },
    ]
    const tab = ref(tabs.findIndex(tab => tab.route === route.value.params.tab))
    watch(() => route.value.params.tab, (newTab) => tab.value = tabs.findIndex(tab => tab.route === newTab))
    const getRouteName = computed(() => {
      if(route.value.params.user_id)
        return 'admin-refunds-user-list'
      if(route.value.params.store_id)
        return 'admin-refunds-store-list'
      return 'admin-refunds-list'
    })
    return {
      getRouteName,
      tab,
      tabs,
      userId: computed(() => route.value.params.user_id),
      storeId: computed(() => route.value.params.store_id),
    }
  }
}
</script>
